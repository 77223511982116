import React from "react";
import serviceS1 from "@/images/SEO-main.png";
import serviceS2 from "@/images/ppc.png";
const ServiceSEOAndPPCContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>SEO</h2>
      <p>
      SEO is essential for any business trying to grow or maintain an online presence. Improving your 
      business's search engine ranking will increase your brand awareness, social credibility, and sales. 
      Our SEO team has the required expertise and time to elevate your business. SEO is a continuously evolving 
      industry, that's why our team is always up to date with the latest industry-leading techniques to ensure 
      your business gets the best results possible and you stay ahead of your competitors.
      </p> 
      <h2>PPC Marketing</h2>
      <p>
      PPC (pay-per-click) is a powerful option to quickly drive traffic to your website. Carefully crafting a 
      successful PPC campaign is vital to maximise your ROI. Our PPC marketing team is highly experienced at
      optimising your click-through and conversion rates, elevating your business's bottom line. Our team produces 
      profitable marketing strategies using paid social and search platform ads.

      </p> 
      <div className="row gaping"> 
        <div className="col-lg-6 col-sm-12 col-md-6"> 
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6"> 
          <h3>SEO & PPC Services</h3>
          <p>
            We offer a number of SEO and PPC marketing options to meet the needs and budget of any business.
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>SEO Content Optimisation
            </li>
            <li>
              <i className="fa fa-check-square"></i>Local & International SEO
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>SEO & PPC Consultancy
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Google Ads & Paid Social Media Ads
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Bing Ads
            </li>
          </ul>
        </div>
      </div>
      <h3>What is best for my business SEO or PPC? </h3>
      <p>
      SEO is a long-term investment with endless possibilities, whereas PPC is an instant way to drive 
      traffic to your website. The biggest difference is that once your website has been optimised for 
      SEO and we begin taking the necessary actions to increase your search engine ranking, you will 
      continue to experience the rewards with just a little maintenance for a long time. However, with PPC, 
      once your marketing budget has run out the flow of traffic to your website will begin to slow down. 
      The most accurate answer to the question is, every business will benefit from a mixture of both.
      </p>
    </div>
  );
};

export default ServiceSEOAndPPCContent;
